<template lang="html">
<div class="container-fluid p-0">

    <!-- 顶部Banner -->
    <div class="container-fluid justify-content-center top-banner">
        <div class="container row banner-box p-0">
            <div class="banner-box-content">
                <h1 class="display-4 banner-box-title">传奇世界</h1>
                <p class="lead">时长版新区—纵横四海12月25日 14:00火爆开启！<br>活动期间，普通场景击杀怪物可以获得双倍经验奖励，炼狱使用双倍经验卡最高可达4倍。</p>
                <hr class="my-4">
                <p>※ 注：获取的经验无法超过当日经验上限</p>
                <a class="btn btn-danger btn-lg" @click="woool" role="button">立即体验</a>
            </div>
        </div>
    </div>

    <div class="container  join-game-banner">
        <!-- 对战邀请 -->
        <div class="input-group mb-3">
            <input type="text" ref="invite_code" class="form-control" placeholder="请输入邀请码" aria-label="请输入邀请码" aria-describedby="button-addon2">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="joinGame">加入游戏</button>
            </div>
        </div>
    </div>

    <div class="container index-game-list">
        <!-- 加载loading效果 -->
        <div v-show="show_loading" class="lcg-loading" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span style="margin-left:5px">正在加载数据...</span>
        </div>

        <!-- 游戏列表 -->
        <div container v-for="game_group,idx in game_groups">
            <GameGroup :games="game_group.games" :name="game_group.name">
            </GameGroup>
        </div>
    </div>
    <MsgModal ref="msg-modal" :title="title" :warningClass="warningClass"></MsgModal>
</div>
</template>
<script>
import GameGroup from '@/components/gameGroup/Index'
import MsgModal from '@/components/MsgModal'
export default {
    name: 'Beta',
    components: {
        GameGroup,
        MsgModal
    },

    data() {
        return {
            show_loading: false,
            game_groups: [],
            title: "",
            warningClass: ""
        }
    },

    created() {},
    mounted() {
        this.request_index_data()
        window.addEventListener('beforeunload', e => {
            this.beforeunloadHandler(e);
        });
    },
    methods: {
        request_index_data() {
            this.show_loading = true;
            this.$API.getRecommendList({
                "limit": 100
            }).then(response => {
                let res = response.data;
                this.game_groups = res.data.list;
                this.show_loading = false;
            }).catch(error => {
                this.show_loading = false;
            })
        },
        requestPlayGame: function (game) {
            let game_window = window.open('/#/cloud/game/' + game.id);
            // console.log("我要启动游戏，ID："+game);
            // this.$emit('startGame',game);
            // this.$eventBus.$emit("launchGameEvent", game);

        },
        beforeunloadHandler(e) {},
        woool() {
            window.open("/#/sdo/game/900");
        },
        joinGame() {
            let invide_code = this.$refs.invite_code.value;
            if (!invide_code) {
                this.title = "请输入邀请码";
                this.warningClass = "warning";
                this.$refs['msg-modal'].show();
            } else {
                this.$API.getStartCloudGame({
                        type: "join_game",
                        password: invide_code
                    }).then(response => {
                        let res = response.data;
                        let result_code = res.code;
                        switch (result_code) {
                            case 50001:
                            case 50021:
                            case 50031:
                            case 50032:
                            case 50033:
                                this.title = res.message;
                                this.warningClass = "warning";
                                this.$refs['msg-modal'].show();
                                this.$refs.invite_code.value = "";
                                return;
                        }
                        if (res.status == 1) {
                            let ws_token = res.data.ws_token.token;
                            window.open("#joinGame/" + invide_code + "/" + ws_token);
                            this.$refs.invite_code.value = "";
                        }
                    })
                    .catch(error => {
                        Promise.reject(error);
                    });
            }
        }

    }
}
</script>

<style lang="scss" scoped>
#wrap .container-fluid{
   background-color: #f2f2f2;
}
.btn-outline-secondary {
    color: #6c757d;
}
.btn-outline-secondary:hover {
    color: #fff;
}
.card {
    cursor: pointer;
}
.card-box {
    transform: scale(1);
    transition: 0.5s;
    // color: $primary-color;
}

.card-box:hover {
    transform: scale(1.04);
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.24);
}

.img-play {
    height: 30px;
    width: 60px;
    background-color: #FFFAF4;
}
</style>
