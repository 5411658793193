<template>
<div>
    <b-modal id="modal-msg" hide-footer centered :content-class="selfModalClass">
        <div class="modal-container">
            <div :class="[successClass, warningClass,errorClass]"> {{title}}</div>
        </div>
    </b-modal>
</div>
</template>

<script>
//消息弹框
export default {
    name: 'Modal',
    data() {
        return {
            selfModalClass: 'modal-launcher',
        }
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        successClass: {
            type: String,
            default: '',
        },
        warningClass: {
            type: String,
            default: '',
        },
        errorClass: {
            type: String,
            default: '',
        },
    },
    methods: {
        show() {
            this.$bvModal.show('modal-msg');
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/.modal-launcher {
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    background: linear-gradient(180deg, #513814 0%, #090601 100%);
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.6);
    opacity: 0.95;
}

/deep/.modal-header {
    border-bottom: none;

    .close {
        color: #ffff;
    }
}

/deep/ .modal-body {
    padding: 0;
    padding-bottom: 50px;
}

.success {
    color: #8ce6b0;
}

.warning {
    color: #ffd77a;
}

.error {
    color: #e83e8c;
}
</style>
