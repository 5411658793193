<template>
<div v-if="gameCount">
    <div class="lcg-subtitle">
        <h3>
            {{name}}
            <small class="text-muted"></small>
        </h3>
    </div>

    <div class="row">
        <div v-for="game in games" class="col-lg-4 col-md-12 mb-4" :key="game.id">
            <div class="card h-100 border-0 card-box">
                <a v-if="platform == 'daoyu'" target="_blank" :href="'/#/sdo/game/'+game.id"><img :src="game.cover.material" class="card-img-top"></a>
                <a v-else target="_blank" :href="'/#/cloud/game/'+game.id"><img :src="game.cover.material" class="card-img-top"></a>
                <div class="card-body">
                    <p class="lcg-card-game-description">{{game.description}}</p>
                    <div class="game-info">
                        <img class="lcg-game-icon" :src="game.logo">
                        <div class="lcg-game-content">
                            <p class="lcg-game-title">
                                {{game.name}}
                            </p>
                            <p class="lcg-game-publisher">{{game.publisher}}</p>
                        </div>
                        <b-button v-if="game.id == 903 && platform != 'daoyu'" variant="info" @click="tryGame(game.id)" style="border-radius: 5px; margin-top: 7px; float: right;">试玩</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>

export default {
    name: 'GameGroup',

    props: {
        games: {
            type: Array,
            default: []
        },
        name: {
            type: String,
            default: ""
        }
    },
    computed: {
        gameCount() {
            return this.games ? this.games.length : 0;
        },
        userName() {
            return this.$store.state.login.user.name ?? '';
        },
        platform() {
           return this.$store.state.login.user.platform ?? '';
        }
    },
    methods: {
        tryGame(gameId) {
            window.open('/#/trial/game/' + gameId);// 试玩
        }
    }
}
</script>
